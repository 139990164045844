// main : stylesheet.scss
#header{
    position: relative;
    z-index: 15;

    .btn-group.open .dropdown-toggle{
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .dropdown-menu{
        p{
            margin-bottom: 0;
        }
    }


    .mobile-bar{
        background: $red;
        color: #fff;
        padding: 10px 0;

        a{
            color: #fff;
            font-size: 20px;
            cursor: pointer;
            &:hover, &.focus{
                color: #ddd;
            }
        }


    }

    .menu-bar{
        padding: 20px 0;
        background: #fff;
        -webkit-box-shadow: 0 4px 15px -2px rgba(0,0,0,0.3);
        -moz-box-shadow: 0 4px 15px -2px rgba(0,0,0,0.3);
        box-shadow: 0 4px 15px -2px rgba(0,0,0,0.3);

        @media (max-width: 767px) {
            padding-bottom: 0;
        }


        &.affix {
            top: 0;
            width: 100%;
            z-index: 500 !important;
            padding: 10px 0;
            .right-part{
                .search-row{
                    display: none!important;
                }

            }

            #logo{
                flex-shrink: 1;
                width: 100px;

            }

            &+ .padding{
                padding-top: 138px;
            }

        }



        .right-part{


            .search-row{
                margin-bottom: 20px;
                @media (max-width: 991px) {
                    margin-top: 20px;
                }
                .generate-order{
                    background: none;
                    margin-left: 20px;
                    i{
                        font-size: 60px;
                        color: $darkgray;
                    }

                    position: relative;
                    .text{
                        position: absolute;
                        bottom: 14px;
                        width: 100%;
                        color: #fff;
                        font-size: 11px;
                        text-align: center;
                        left: 0;
                        line-height: 1.3;

                    }

                    &:hover,&:focus{
                        background: none;
                        i{
                            color: $red;
                        }

                    }
                }
                #search{

                    max-width: 250px;

                    input{
                        border: 0;
                        border-radius: 0;
                        box-shadow: none;
                        outline: none;
                        border-bottom: 1px solid $gray;
                        padding: 20px 0 10px;
                    }
                    button{
                        border: 0;
                        border-radius: 0;
                        box-shadow: none;
                        outline: none;
                        font-size: 25px;
                        color: $lightgray;
                        border-bottom: 1px solid $gray;
                        border-top: 1px solid #fff;
                        line-height: 46px;
                        padding: 0 5px;
                        height: 46px;
                        &:hover, &:focus{
                            background: none;
                            color: $darkgray;
                        }
                    }


                }
                @media (max-width: 767px) {
                    position: absolute;
                    top: calc(100% + 48px);
                    width: 100%;
                    background: $darkgray;
                    left: 0;
                    right: 0;
                    padding: 20px;
                    margin: 0;
                    display: none;
                    #search{
                        width: 100%;
                        max-width: 100%;
                        input{
                            padding: 10px 16px;
                        }
                        button{
                            padding: 0 12px;
                            &:hover, &:focus{
                                background: #fff;
                                color: $red;
                            }
                        }
                    }

                }
                @media (min-width: 768px) {
                    display: block!important;
                }
            }


            #menu-bar{
                border: 0px;
                border-radius: 0px;

                margin-bottom: 0;


                #main-menu{
                    > li{

                        position: relative;
                        > a{
                            color: #000;
                            font-size: 16px;
                            line-height: 14px;
                            padding: 0 10px;
                            font-weight: 400;
                            cursor: pointer;
                            text-transform: uppercase;
                            @media (max-width: 1199px) {
                                padding: 0 10px;
                                font-size: 15px;
                            }

                            @media (max-width: 991px) {
                                font-size: 15px;

                            }
                            &:hover, &:focus{

                                color: $red;
                                background: none;
                            }


                        }
                        &:hover, &:focus{
                            > a{
                                color: $red;
                                background: none;
                            }

                            .submenu{
                                //opacity: 1;
                                //visibility: visible;
                            }
                        }
                        &.active{
                            > a{
                                color: $red;
                            }

                        }

                        &:last-child{
                            a{
                                padding-right: 0;
                            }
                        }

                        .submenu{
                            opacity: 0;
                            visibility: hidden;
                            position: absolute;
                            top:100%;
                            left: 0;
                            width: 100%;
                            background: #fff;
                            border: 5px solid #ccc;
                            width: 193px;
                            padding: 5px 15px;
                            margin-bottom: 0;
                            li{
                                a{
                                    color: #666;
                                    padding: 7px 0;
                                    color: $black;
                                    font-size: 14px;
                                    border-bottom: 1px solid #b8b9bb;
                                    display: block;
                                    &:hover, &:focus{
                                        color: $red;
                                        text-decoration: none;
                                    }
                                }

                                &:last-child{
                                    a{
                                        border-bottom: 0px solid #b8b9bb;
                                    }
                                }
                            }


                        }
                    }
                }


                @media (max-width: 767px) {
                    position: absolute;
                    top: calc(100% + 48px);
                    width: 100%;
                    background: $darkgray;
                    left: 0;
                    right: 0;
                    display: none;

                    #main-menu{


                        margin: 0;
                        padding: 0;
                        > li{
                            padding: 0 20px;
                            > a{
                                display: block;
                                padding: 20px 0;
                                font-size: 20px;
                                border-bottom: 1px solid #ddd;
                                text-align: center;
                                color: #fff;

                            }

                            &:last-child{
                                a{
                                    border-bottom: 0px;
                                }
                            }

                        }
                    }
                }

                @media (min-width: 768px) {
                    display: block!important;
                }



            }




        }

        #logo{

            flex-shrink: 0;
            width: 275px;

            @media (max-width: 767px) {
                margin-bottom: 20px;
            }

        }







    }


    .top-bar{
        background: $darkgray;
        color: #fff;

        a.tel{
            color: #fff;
            font-size: 18px;
            line-height: 20px;
            i{
                font-size: 20px;
                margin-right: 5px;
            }
            &:focus, &:hover{
                color: #ccc;
            }
        }

        .social-links{
            margin: 0;

            li{
                padding: 0;
                margin-left: -5px;
                &:focus, &:hover{
                    background: $red;
                }
            }
            a{
                color: #fff;
                display: inline-block;
                padding: 8px 8px;
                font-size: 18px;
                &:focus, &:hover{
                    background: $red;

                }

                @media(max-width: 360px){
                    font-size: 15px;
                }
            }
        }


    }

    #cart {
        color: #000;
        margin-left: 15px;

                    @media(max-width: 767px){
                        margin-left: 0;
                    }
        > button {
            padding: 0;
            color: #000 !important;
            background: transparent;
            &.open {
                background: transparent;
            }
            &:active {
                box-shadow: none;
                color: #000;
            }
            &:hover {
                color: #000;
            }
        }
        .dropdown-menu {
            width: 467px;
            border-radius: 0;
            padding: 10px;
        }
    }

    .menu-bar #cart {
        tr > td:last-child, tr > td:first-child {
            display: none;
        }
    }
}
