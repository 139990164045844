// compileCompact: ../stylesheet.css

@import '_fonts';
@import '_colours';
@import '_header';
@import '_footer';
@import '_home';
@import '_product';
@import '_media';
@import '_category';
@import '_about';
@import '_team';
@import '_contact';
@import '_blog';
@import '_order_screen';
@import '_checkout';

.btn-danger{
  background: $red;
}

.row{
    &:before, &:after{
        display: flex;
    }
}

.modal-open{

}
.emoji {
    max-width: 25px;
}
@media screen and (max-width: 767px) {
.table-responsive {

    border: 0px solid #ddd;
}
}

body{

    font-family: 'Futura';
    font-weight: 300;
    color: #010101;
    font-size: 18px;
    @media (max-width: 767px) {

        font-size: 16px;
    }
    a{
        color: $red;

        &:hover, &:focus{
            color: darken($red,10%);
            text-decoration: none;
        }
    }

    .flex-grow-1{
        flex-grow: 1;
    }


}

body.iosBugFixCaret.modal-open { position: fixed; width: 100%; }

img{
    max-width: 100%;
}

.transition{
     text-decoration: none;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

a{
    text-decoration: none;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}


.font-italic{
    font-style: italic;
}

.font-heavy{
    font-weight: 600;
}

.font-book{
    font-weight: 400;
}
.font-bold{
    font-weight: bold;
}


.font-light{
    font-weight: 300;
}

.font-normal{
    font-weight: 400;
}

.font-medium{
    font-weight: 500;
}

.text-uppercase{
    text-transform: uppercase;
}


.white-button{
    padding: 10px 20px;
    border-radius: 0;
    text-transform: uppercase;
    font-size: 14px;
    background: #fff;
    font-weight: 600;
    color: #000;
    display: inline-block;

    &:hover, &:focus{
        background: $red;
        text-decoration: none;
        color: #fff;
    }

}

.gray-button{
    padding: 10px 20px;
    border-radius: 0;
    text-transform: uppercase;
    font-size: 20px;
    background: $gray;
    font-weight: 500;
    color: #fff;
    text-align: center;
    &:hover, &:focus{
        background: $red;
        text-decoration: none;
        color: #fff;
    }

}
.red-button{
    padding: 10px 20px;
    border-radius: 0;
    text-transform: uppercase;
    font-size: 14px;
    background: $red;
    font-weight: 500;
    color: #fff;
    text-align: center;
    &:hover, &:focus{
        background: $darkgray;
        text-decoration: none;
        color: #fff;
    }

}
.darkred-button{
    padding: 10px 20px;
    border-radius: 0;
    text-transform: uppercase;
    font-size: 14px;
    background: $darkred;
    font-weight: 600;
    color: #fff;
    text-align: center;
    &:hover, &:focus{
        background: #fff;
        text-decoration: none;
        color: $darkred;
    }

}

.section{
    padding: 30px 0;

    @media (max-width: 991px) {
         padding: 50px 0;
    }
    @media (max-width: 767px) {
         padding: 40px 0 20px;
    }
    @media (max-width: 575px) {
         padding: 20px 0 20px;
    }
    &.white-section{
        background: #fff;
    }
    &.gray-section{
        background: $lightgray;
        padding-top: 50px;


        @media (max-width: 991px) {
            padding-top: 40px;
            padding-bottom: 30px;

        }
        @media (max-width: 767px) {
            padding-top: 30px;
            padding-bottom: 20px;
        }
        @media (max-width: 575px) {
            padding-top: 20px;
            padding-bottom: 0;

        }
    }
}


.pagination{
    > li{
        border: 0px!important;
        > a, > span{
                border-radius: 0px;
                color: #666;
                font-weight: bold;
                &:hover, &:focus{
                    color: $red;
                }
        }
        span{
        }
        &:first-child, &:last-child{
            > a, >span{
                border-radius: 0px;
            }
        }

        &.active{
            > a, > span{
                border-color: #ddd;
                color: #fff;
                background: $red;
                &:hover, &:focus{
                    color: #fff;
                    background: $red;
                }
            }
        }
    }
}

.category-header{
    background: $gray;
    padding: 40px 0;
    font-size: 18px;
    .h1{
        font-size: 50px;
        margin-top: 0;
        margin-bottom: 20px;
        @media (max-width: 1199px) {
           font-size: 45px;
       }
        @media (max-width: 991px) {
           font-size: 40px;
       }
       @media (max-width: 767px) {
            font-size: 30px;
       }
       @media (max-width: 767px) {
            font-size: 25px;
            margin-bottom: 10px;
       }
    }

    @media (max-width: 991px) {
         padding: 30px 0;
         font-size: 16px;
    }
    @media (max-width: 767px) {
         padding: 20px 0;
         font-size: 18px;
         font-size: 15px;
    }

    img{
        flex-basis: 100%;
        max-width: 100%;
    }


}

.breadcrumb-container{
    background: $red;
    padding: 15px 0;
    border-bottom: 10px solid $darkred;
    .breadcrumb{
        background: none;
        margin: 0;
        padding: 0;
        li{
            color: #fff;
            font-size: 18px;
            a{
                color: #fff;
                font-size: 18px;
                @media (max-width: 767px) {

                    font-size: 15px;
               }
               @media (max-width: 575px) {

                    font-size: 13px;
               }

            }
            &:first-child{
                a{
                    font-weight: 500;
                }
            }

        }
        li+li:before {
            @media (max-width: 767px) {

                    content: "/";
               }

        }
    }

    .generate-order{
        font-size: 20px;
        color: #fff;
        font-weight: 600;
        @media (max-width: 767px) {

            font-size: 15px;
        }
        @media (max-width: 575px) {

            font-size: 13px;
        }
    }
}

#content{
    padding-top: 50px;
    padding-bottom: 50px;
    @media (max-width: 991px) {
       padding-top: 30px;
        padding-bottom: 30px;
    }
    @media (max-width: 767px) {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

.top-image{
    height: 500px;
    @media (max-width: 1199px) {
        height: 47vmin;
    }
    @media (max-width: 991px) {
        height: 40vmin;
    }
    background-size: cover;
    background-position: 50% 50%;


}


.downloads-list{
    margin: 30px 0;
    a{
        img{
            margin-right: 20px;
            width: 30px;

        }
        color: $black;

        &:hover, &:focus{
            color: $red;
        }
        margin-bottom: 20px;
    }
}
