// main : stylesheet.scss
.red-button {
  cursor: pointer;
}

.category-list {
  ul {
    padding-left: 0;

    li {
      list-style: none;

    }
  }

  #demo>li>h2 {
    background: none;
    color: black;
  }

  h2.treelevel0 {
    display: none;
  }

  h2.treelevel2 {
    background: rgba(255, 255, 255, 0);
    color: black;
  }

  h2.treelevel1 {
    background: $red;
  }

  h2 {

    margin: 0;
    color: #fff;
    padding: 10px 15px;
    font-size: 20px;
    width: 100%;
  }

}

#product-category {
  .product-layout.product-list:nth-child(even) {
    background-color: #f2f2f2
  }

  .product-list {

    h4 {}

    .product {
      display: flex;
      align-items: center;

      .productlink {
        display: flex;
        align-items: center;
        width: 80%;

        .category-title {
          width: 50%;

        }

        .model {
          width: 20%;
        }

        .price {
          width: 10%;
          margin-bottom: 0;
        }
      }
    }


    .grid-button-group {
      display: none;
    }

    .list-button-group {
      margin-left: auto;

      .red-button {
        display: inline-block;
      }
    }

    .image {
      width: 60px;
    }
  }

  .product-grid {
    .list-button-group {
      display: none;
    }

    .model,
    .price {
      display: inline-block;
    }

    .category-title {
      padding-left: 0;
    }
  }

  .section {
    padding-top: 0;
  }

  .category,
  .product {

    flex-basis: 100%;
    max-width: 100%;

    img {

      max-width: 100%;
    }

    .image {
      height: auto;
    }

    margin-bottom: 30px;

    h2 {
      background: $red;
      margin: 0;
      color: #fff;
      padding: 10px 15px;
      font-size: 20px;
      flex-basis: 25%;
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: flex-start;

      &.product-title {
        font-size: 16px;
      }
    }

    &:hover,
    &:focus {
      h2 {
        background: darken($red, 10%);
      }
    }

  }

  .product {
    h4 {
      padding: 10px 15px;
      font-size: 20px;
      color: #000;

    }

    &:hover,
    &:focus {
      h4 {
        color: $red;
        text-decoration: none;
      }


    }
  }

}

#product-brochure-category {
  .product-layout.product-list:nth-child(even) {
    background-color: #f2f2f2
  }

  .section {
    padding-top: 0;
  }

  .product-list {

    .product {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .productlink {

        display: flex;
        align-items: center;
        flex-basis: 100%;
        max-width: 100%;

        .category-title {
          flex-basis: 50%;
          max-width: 50%;
          padding: 5px 10px;

        }

        .model {
          flex-basis: 35%;
          max-width: 35%;
          padding: 5px 10px;
        }

        .noprice {
          flex-basis: 75%;
          max-width: 75%;
          text-align: right;
        }

        .price {
          flex-basis: 15%;
          max-width: 15%;
          text-align: right;
          padding: 5px 10px;
        }

        &:hover,
        &:focus {
          color: $red;
        }
      }
    }

  }

}



.category-header-white {
  background: white !important;

}