#footer{

    background: #373737;
    font-size: 15px;

    .further-information{

        h4 {
            text-align: center;
            display: flex;
            flex-direction: row;
            justify-content: center;
            font-size: 40px;
            margin-bottom: 60px;
            @media (max-width: 1199px) {
                font-size: 37px;
                margin-bottom: 60px;
            }

            @media (max-width: 991px) {
                font-size: 35px;
                margin-bottom: 50px;

            }
            @media (max-width: 767px) {
                font-size: 30px;
                margin-bottom: 40px;
            }
            @media (max-width: 575px) {
                font-size: 25px;
                margin-bottom: 30px;

            }
        }

        h4:before, 
            h4:after {
            flex-grow: 1;
            height: 3px;
            content: '\a0';
            background-color: $gray;
            position: relative;
            top: 0.5em;
            
            display: flex;
        }

        h4:before {
            margin-right:30px;
        }

        h4:after {
            margin-left:30px;
        }


        .inner{
            padding: 30px;
            background: #fff;
            margin-bottom: 30px;
            width: 100%;
            text-align: center;
            padding-bottom: 80px;
            position: relative;
            box-shadow: 0px 0px 0px 0 rgba(0,0,0,0.1);
            -webkit-transition: box-shadow 200ms ease;
            &:hover{
                box-shadow: 5px 5px 13px 0 rgba(0,0,0,0.1);
            }
            h5{
                font-size: 16px;
                margin-bottom: 5px;
            }

            .icon{
                padding: 10px 0 0;
                img{
                   
                }
                
                &.last-icon{
                    padding-left: 30px;
                    @media (max-width: 1199px) {
                            padding-left: 130px;
                    }

                    @media (max-width: 991px) {
                            padding-left: 38%;

                    }
                    @media (max-width: 767px) {
                            padding-left: 0%;

                    }
                }
            }
            .content{
                margin-bottom: 10px;
                p{
                    font-size: 14px;
                }
                img{
                        position: absolute;
                        bottom: 80px;
                        width: 60%;
                        right: 10px;
                        
                        @media (max-width: 1199px) {
                            width: 20%;
                            right: 28%;
                        }

                        @media (max-width: 991px) {
                            width: 95px;
                            right: 20%;

                        }
                        @media (max-width: 767px) {
                                display: none;
                        }
                }
            }

            a.gray-button{
                position: absolute;
                bottom: 30px;
                left: 30px;
                display: block;
                margin: 0 auto;
                font-size:13px;
                width: calc(100% - 60px);
            }
            
            form{
                input.form-control{
                   border-radius: 0px;
                }
            }

        }
    }
    
    .footer-row{
        padding: 40px 0;
        border-bottom: 1px solid #a1a1a1;
        color: #fff;
        a{
            color: #fff;
            &:hover,&:focus{
                color: $red;
            }
            &.tel{
                font-size: 20px;
            }
        }
        
        .social-links{
            margin: 0;
            margin-left: 10px;
            a{
                color: #999;
                font-size: 20px;
                &:hover{
                        color: #fff;
                }
            }
            
        }

        .column-1{

        }
        .column-2{
            text-align: left;
            @media (max-width: 1199px) {
                text-align: right;
            }
            @media (max-width: 991px) {
                text-align: left;
            }
        }
        .column-3{
            text-align: right;
            @media (max-width: 991px) {
                text-align: left;
            }
        }
    }
    


}