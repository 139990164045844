// main : stylesheet.scss
.category-list{
  @media (min-width: 1400px){

        //max-width: 1340px !important;
  }
}
#app.order-screen {
    .subcategory-name {
        background-color: $red;
        color: #fff;
        padding: 5px 10px;
    }
    .nav-pills>li.active>a, .nav-pills>li.active>a:focus, .nav-pills>li.active>a:hover {
        background: $red;
        border-radius: 0;
    }
    .nav>li>a {
        padding: 5px 15px;
    }
    .searching .sub-products {
        tr:not(.active) {
            opacity: 0.5
        }
    }
    #search-bar {
        margin-bottom: 20px;
        input {
            border-radius: 0;
        }
        .input-group-addon {
            border-radius: 0;
            background: $red;
            color: #fff;
        }
    }
    table.product-table {
        td.name{
          width:45%;
        }
        td.model{
          width:25%;
        }
        td {
            vertical-align: middle;
            &.add {
                width: 115px;
                text-align: center;
            }
            .quantity {
                display: flex;
                > * {
                    vertical-align: top;
                    float: left;
                    width: 28px;
                    text-align: center;
                    &.fa {
                        background: $red;
                        color: #fff;
                        background: #c1022f;
                        color: #fff;
                        padding: 5.5px 0px;
                    }
                    &.fa-spin {
                        background: transparent;
                        color: #000;
                        width: 42px;
                    }
                }

                input {
                    width: 42px;
                    border-left: 0;
                    border-right: 0;
                    border-top: 1px solid #ccc;
                    border-bottom: 1px solid #ccc;
                }

            }

            &.image-col {
                width: 25%;
                img {
                    max-width: 200px;
                }
            }
            &.info-col {
                vertical-align: top;
                padding: 0;
                width: 75%;
                table {
                    border-left: 0;
                    border-right: 0;
                    margin-bottom: 0;
                    td {
                        border-left: 0;
                        border-right: 0;
                    }
                }
            }
        }
    }
    #cart {
        color: #000;
        > button {
            padding: 0;
            color: #fff !important;
            background: transparent;
            &.open {
                background: transparent;
            }
            &:active {
                box-shadow: none;
                color: #fff;
            }
            &:hover {
                color: #fff;
            }
        }
        .dropdown-menu {
            width: 467px;
            border-radius: 0;
            padding: 10px;
        }
    }

    #search-bar {
        //position: sticky;
        //top: 68px;
        input {
            font-size: 16px;
            height: 35px;
        }
        .input-group-addon {
            padding: 6px 11px;
        }
    }
}
