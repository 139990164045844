#media-module{
    
    .media-desc{
        margin-top: 30px;
    }
    
    .animate-bottom {
            position: relative;
            -webkit-animation-name: animatebottom;
            -webkit-animation-duration: 1s;
            animation-name: animatebottom;
            animation-duration: 1s
          }

          @-webkit-keyframes animatebottom {
            from { bottom:-100px; opacity:0 } 
            to { bottom:0px; opacity:1 }
          }

          @keyframes animatebottom { 
            from{ bottom:-100px; opacity:0 } 
            to{ bottom:0; opacity:1 }
          }
    
    .loading{
            background: #fff;
            padding: 100px 0; 
            position: relative;
            margin-bottom: 200px;
        
        #loader {
            position: absolute;
            left: 50%;
            top: 50%;
            z-index: 1;
            width: 150px;
            height: 150px;
            margin: -75px 0 0 -75px;
            border: 16px solid #f3f3f3;
            border-radius: 50%;
            border-top: 16px solid $red;
            width: 120px;
            height: 120px;
            -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
          }

          @-webkit-keyframes spin {
            0% { -webkit-transform: rotate(0deg); }
            100% { -webkit-transform: rotate(360deg); }
          }

          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
          
          
    }
    .buttons{
        margin-bottom: 30px;
        .media-button{
            padding: 5px 0;
            width: 150px;
            text-align: center;
            font-size: 22px;
            display: inline-block;
            margin: 10px;
            
            &.twitter{
                border: 2px solid $black;
                color: $black;
                background: #fff;
                
            }
            &.news{
                border: 2px solid $black;
                background: $darkgray;
                color: #fff;
                

            }
            &.youtube{
                border: 2px solid $black;
                color: #fff;
                background: #cd201f;
                

            }
            &.linkedin{
                border: 2px solid $black;
                color: #fff;
                background: #0077B5;
                

            }
            
            
            &.active{
                font-weight: 900;
            }

        }
    }
    

    margin-top: 40px;
    .item{
        flex-basis: 100%;
        margin-bottom: 30px;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;    
        -o-transition: all 0.3s; 
        transition: all 0.3s;
        box-shadow: 5px 5px 22px 5px rgba(0, 0, 0, 0.05);
        
        &:hover{
                box-shadow: 12px 12px 33px 0 rgba(0, 0, 0, 0.1);
        }

        
        color: $black;

        .inner{
            flex-basis: 100%;
            .image{
                height: 220px;
                display: block;
            }      
            position: relative;

            .info{
                padding: 10px 20px 45px;
                margin: 20px 0;
                @media (max-width: 767px) {
                    margin: 10px 0;
                }
               
                h4{
                    font-size: 20px;
                    @media (max-width: 1199px) {
                        font-size: 19px;
                    }
                    @media (max-width: 991px) {
                        font-size: 18px;
                    }
                    @media (max-width: 767px) {
                        font-size: 17px;
                    }
                    @media (max-width: 575px) {
                        font-size: 16px;
                    }
                    line-height: 1.2;
                    margin-top:0;
                    
                    .emoji{
                        max-width: 23px;
                    }
                }

            }
            .bottom{
                position: absolute; 
                bottom: 20px;
                left: 20px;
                width: calc(100% - 40px);
                .details{
                    font-size: 16px;
                }
                .type{
                    font-size: 25px;
                    
                    display: inline-block;
                    background: none;

                }

            }
        }
        
        &.youtube-item{
            background: #cd201f;
            color: #fff;
            &:hover, &:focus{
                background: darken(#cd201f, 2%);
            }
            
            
        }
        &.twitter-item{
            
            background: #fff;
            
            &:hover, &:focus{
                background: darken(#fff, 2%);
            }

            .inner{
                .info{
                    padding: 10px 20px 0;
                    margin: 20px 0 0;
                    @media (max-width: 767px) {
                        margin: 10px 0 0;
                    }
                }

                .view-on-twitter{
                    margin-bottom: 20px;
                    padding: 5px 10px 5px;
                    text-align: right;
                    font-size: 12px;
                    color: #666;
                    letter-spacing: 2px;
                    border: 1px solid #e4e4e4;
                    margin: 15px 20px 70px;
                    &:hover{
                        background: #e4e4e4;
                        color: #000!important;
                    }
                }
            }
            
        }
        
        &.linkedin-item{
            
            background: #0077B5;
            color: #fff;
            &:hover, &:focus{
                background: darken(#0077B5, 2%);
            }
            a{
                color: #94f0ff;
            }
            .info{
                h4{
                    display: none;
                }
            }
            
        }
        
        &.news-item{
            background: $darkgray;
            color: #fff;
            &:hover, &:focus{
                background: darken($darkgray, 2%);
            }
            
            
        }
        
        

    }

}