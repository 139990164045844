#information-about{
    
    h1{
        margin-bottom: 30px;
    }
    font-size: 20px;
    
   
    @media (max-width: 1199px) {
        font-size: 19px;
    }
    @media (max-width: 991px) {
        font-size: 18px;
    }
    @media (max-width: 767px) {
        font-size: 17px;
    }
    @media (max-width: 575px) {
        font-size: 16px;
    }
    
    
}