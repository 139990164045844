// main : stylesheet.scss
#checkout-cart {
    table {
        thead {
            background: $red;
            color: #fff;
            font-size: 20px;
        }
    }
    input, .btn {
        border-radius: 0;
    }
    .quantity{
      min-width: 50px;
    }
}


#checkout-checkout {
    .panel {
        border-radius: 0;
        .panel-heading {
            background: $red;
            color: #fff;
            a:hover, a:focus{
                color: #fff;
            }
        }
    }

    #button-confirm {
        padding: 10px 20px;
        border-radius: 0;
        text-transform: uppercase;
        font-size: 14px;
        background: $red;
        font-weight: 500;
        color: #fff;
        text-align: center;
        border: 0;
        &:hover, &:focus{
            border: 0;
            background: $darkgray;
            text-decoration: none;
            color: #fff;
        }
    }

}
