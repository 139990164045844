#information-team{
    
    .map{
        margin: 0 auto;
        max-width: 345px;
        flex-basis: 345px;
    }
    
    .person{
        margin-bottom: 30px;
        width: 100%;
        font-size: 20px;
        @media (max-width: 1199px) {
            font-size: 19px;
        }
        @media (max-width: 991px) {
            font-size: 18px;

        }
        @media (max-width: 767px) {
            font-size: 17px;
        }
        @media (max-width: 575px) {
            font-size: 16px;
        }
        .text{
            line-height: 1.4;
        }
        h3{
            margin-top: 0;
            background: $red;
            color: #fff;
            font-size: 24px;
            padding: 5px 10px;
            margin-bottom: 5px;
            @media (max-width: 1199px) {
                font-size: 22px;
            }
            @media (max-width: 991px) {
                font-size: 20px;

            }
            @media (max-width: 767px) {
                font-size: 18px;
            }
            
        }
        h5{
            margin-top: 0;
            background: $lightgray;
            color: #000;
            font-size: 24px;
            padding: 5px 10px;
            margin-bottom: 10px;
            @media (max-width: 1199px) {
                font-size: 22px;
            }
            @media (max-width: 991px) {
                font-size: 20px;

            }
            @media (max-width: 767px) {
                font-size: 18px;
            }
            
        }
        .social{
            a{
                width: 30px;
                height: 30px;
                
                
                display: inline-block;
                border-radius: 50%;
                background: $red;
                i{
                    color: #fff;
                    line-height: 30px;
                    display: block; 
                    text-align: center;
                    font-size: 18px;
                }
                &:hover, &:focus{
                    background: darken($red, 10%);
                    text-decoration: none;
                }
                
            }
        }
    }
}