// main : stylesheet.scss
#common-home{
  .section{
    padding-top: 30px;
    padding-bottom:20px;
  }
}
#home-slider{
    position: relative;
    z-index: 10;
    padding: 0;

    .container{
        position: relative;
        z-index: 10;
    }
    height: 600px;
    @media (max-width: 1199px) {
        height: 50vmin;
    }
    @media (max-width: 991px) {
        height: 42vmin;
    }

    margin-top: 0;
    .welcome-message{
        //width: 200px;
        color: #fff;
        margin-top: 40px;
        text-align: center;
        line-height: 1.2;
        margin-left: auto;

        .top{
            background: $darkgray;
            padding: 10px 20px;
            color: #fff;
            font-size: 20px;
            font-weight:  600;
        }
        .bottom{
            background: #c8c6c5c4;
            padding: 10px;
        }


    }

    .slides{
        height: 600px;
        position: absolute;

        z-index: 5;
        top: 0;
        left:0;
        width: 100%;
        @media (max-width: 1199px) {
            height: 50vmin;
        }
        @media (max-width: 991px) {
            height: 42vmin;
        }



        .item{
            background-size: cover;
            background-position: 50% 50%;
            height: 600px;
            @media (max-width: 1199px) {
                height: 50vmin;
            }
            @media (max-width: 991px) {
                height: 42vmin;
            }


        }


    }


}

.order-cut-off{
    color: #ad1b1f!important;
    background: none!important;
    margin-bottom: 30px;
    border: 3px solid #c8c6c5;
    .terms{
       font-size:9px; color:#ddd; float:right;
    }
}


.red-banner{
    background: $red;
    border-bottom: 20px solid $darkred;
    padding: 30px 0;


    @media (max-width: 767px) {
        padding: 20px 0;
        border-bottom: 10px solid $darkred;
    }




    h3{
        font-size: 42px;
        color: #fff;
        margin: 0;
        line-height: 1.3;
        text-align: center;
        font-weight: 400;
        @media (max-width: 1199px) {
            font-size: 32px;
        }
        @media (max-width: 991px) {
            font-size: 30px;
        }
        @media (max-width: 767px) {
            font-size: 25px;
        }
        @media (max-width: 575px) {
            font-size: 22px;
        }
    }

    h1{
        font-size: 42px;
        color: #fff;
        margin: 0;
        line-height: 1.3;
        text-align: left;
        font-weight: 400;
        @media (max-width: 1199px) {
            font-size: 32px;
        }
        @media (max-width: 991px) {
            font-size: 30px;
        }
        @media (max-width: 767px) {
            font-size: 25px;
        }
        @media (max-width: 575px) {
            font-size: 22px;
        }
    }
    .gray-button{
        margin-left: 20px;
        display: inline-block;
        &:hover, &:focus{
            background: $darkred;
        }
        @media (max-width: 767px) {
            margin-top: 20px;
            margin-left: 0px;
        }

    }
}

.gray-block{
    padding: 30px;
    background: $lightgray;
    margin-bottom: 30px;

    @media (max-width: 767px) {
        padding: 20px;
    }
    @media (max-width: 575px) {
        padding: 10px;
    }
    .inner{
        padding: 30px;
        background: #ecebe8;
        width: 100%;

        @media (max-width: 767px) {
            padding: 20px;
        }
        @media (max-width: 575px) {
            padding: 10px;
        }
        h3{
            font-size: 50px;
            color: #000;
            margin-top: 0;

            @media (max-width: 991px) {
                font-size: 45px;
            }
            @media (max-width: 767px) {
                font-size: 40px;
            }
            @media (max-width: 575px) {
                font-size: 35px;
            }
        }
        .category-list{
            margin-top: 30px;
            @media (max-width: 767px) {
                margin-top: 20px;
            }
            @media (max-width: 575px) {
                margin-top: 10px;
            }
            a{
                display: block;
                margin-bottom: 10px;
                font-size: 13px;
            }
        }
    }
}

.home-boxes{
    .row{

        > div .inner{
            padding: 20px 30px;
            width: 100%;
            padding-bottom: 80px;
            position: relative;
            margin-bottom: 30px;
            background-size: cover;
            background-position: 50% 50%;
            @media (max-width: 991px) {
                padding: 20px 20px;
            }
            h3{
                font-size: 50px;
                color: #fff;
                margin-top: 0;
                @media (max-width: 1199px) {
                    font-size: 40px;
                }
                @media (max-width: 991px) {
                    font-size: 35px;
                }
            }

            .text{
                color: #fff;
                max-width: 60%;
                img{
                    max-width: 45%;
                }
            }

            .white-button, .darkred-button{
                position: absolute;
                bottom: 25px;
                left: 30px;
                display: inline-block;
                @media (max-width: 991px) {
                    left: auto;
                    bottom: auto;
                    margin-top: 10px;
                    position: relative;
                }
            }
        }

        > div:nth-child(1) .inner{

            background-color: $gray;
        }
        > div:nth-child(2) .inner{
            background-color: $red;
           
        }
        > div:nth-child(3) .inner{
            background-color: $darkgray;
            position: relative;
            overflow: hidden;
            img{
                position: absolute;
                bottom: 10px;
                right: 10px;
            }
        }
    }
}
