
#information-contact{
    
    h4{
        font-size: 20px;
        color: $red;
        margin-bottom: 5px;
    }
    
    #map{
        @media (max-width: 767px) {
            margin-top: 30px;
        }
    }

    .enquiry-form{
        
        @media (max-width: 991px) {
            margin-bottom: 30px;
        }
        @media (max-width: 767px) {
            margin-top: 30px;
            margin-bottom: 0;
        }
        
        
        
        h2{
            padding: 20px;
            margin: 0;
            background: $red;
            color: #fff;
        }

        .inner{
            background: $black;
            padding: 20px;
            p{
                color: #fff;
                margin-bottom: 20px;
            }
            form{

                textarea{
                    border-radius: 0px;
                    border: 4px solid #ccc;
                    font-size: 16px;
                }

                input.form-control{
                    border-radius: 0px;
                    border: 4px solid #ccc;
                    font-size: 16px;
                    height: 45px;
                }
                
                .red-button{
                    cursor: pointer;
                }

            }
        }
        
        .error{
            margin-top: 20px;
            margin-bottom: 10px;
            display: none;
        }
        
        em{
            display: block;
            color: #fff;
        }
        
        .success{
            color: #fff;
        }

    }

}

