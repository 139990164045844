.blog{

    h1{
        margin: 0;
        margin-bottom: 20px;
        padding: 7px 15px;
        padding-bottom: 7px;
        border-bottom: 1px solid #fff;
        background: #999;
        color: #fff;
        font-weight: 800;
        font-family: Raleway;
        font-size: 27px;


    }

    .blog_grid_holder{
        padding-top: 0;
    }
    .red-button{
        font-size: 14px;
        letter-spacing: 1px;
        letter-spacing: 1px;
        padding: 5px 10px;
        margin-top: 5px;
    }

    .summary{

        .blog_stats{
            font-size: 14px;
            border-bottom: 0px;
            color: #999!important;
            padding-bottom: 0;
            margin: 0;
        }

        h2.blog_title{
            font-size: 35px;
            font-weight: 800;
            font-family: Raleway;
            color: #666;
            margin-bottom: 10px;

            @media (max-width: 991px) {
                font-size: 30px;
            }
            @media (max-width: 767px) {
                font-size: 25px;

            }

            a{
                color: #666;
                &:hover, &:focus{
                    text-decoration: none;
                    color: $red;
                }
            }
        }

        .image{
            border: 4px solid #ccc;
            margin-bottom: 15px;
            a{
                display: block; 
                background-size: cover;
                background-position: 50% 50%;
                height: 300px;
                @media (max-width: 991px) {
                    height: 250px;
                }
                @media (max-width: 767px) {
                    height: 200px;

                }
                @media (max-width: 575px) {
                    height: 160px;

                }
            }
        }
    }


    &.blog_post{
        h1{
            font-size: 35px;
            font-weight: 800;
            font-family: Raleway;
            color: #666;
            margin-bottom: 10px;
            background: none;
            padding: 0;
            @media (max-width: 991px) {
                font-size: 30px;
            }
            @media (max-width: 767px) {
                font-size: 25px;

            }
        }
        .blog_stats{
            font-size: 14px;
            border-bottom: 0px;
            color: #999!important;
            padding-bottom: 0;
            margin: 0;
        }

        .main_thumb{
            padding: 0;
            margin-top: 5px;
            border: 4px solid #ccc;
            margin-bottom: 15px;
            display: block; 
            background-size: cover;
            background-position: 50% 50%;
            height: 300px;
            @media (max-width: 991px) {
                height: 250px;
            }
            @media (max-width: 767px) {
                height: 200px;

            }
            @media (max-width: 575px) {
                height: 160px;

            }
        }
    }



}

.blog-module{

    h3{
        margin: 0;
        padding: 7px 15px;
        padding-bottom: 7px;
        border-bottom: 1px solid #fff;
        background: $red;
        color: #fff;
        font-weight: 800;
        font-family: Raleway;
        font-size: 27px;
        margin: 0;
        @media (max-width: 991px) {
            font-size: 20px;
        }
        @media (max-width: 767px) {
            font-size: 18px;

        }

    }

    &.blog-category{
        ul{
            background: #333333;
            color: #fff;
            li{
                border:0;
                a{
                    color: #fff;
                    font-size: 15px;
                    padding: 7px 15px;
                    line-height: 1.3;
                    &:hover, &:focus{
                        background: #ccc;
                        color: #333;
                        text-decoration: none;
                    }
                }

               
            }
        }
    }

    &.latest
    {

        .blog_item{

            margin-bottom: 15px!important;
            .summary{
                padding: 10px 15px;
                font-size: 13px;
                padding-bottom: 0;
                .inner{
                    padding-bottom: 25px;
                    border-bottom: 1px solid #d5d5d5;
                }
                h2.blog_title{
                    font-size: 18px;
                    font-weight: 700;
                    font-family: Lato;
                    margin-bottom: 5px;

                    a{
                        color: #666;
                        &:hover, &:focus{
                            text-decoration: none;
                            color: $red;
                        }
                    }
                }

                .latest-link{
                    color: $red;
                    cursor: pointer;
                    text-transform: uppercase;
                    font-weight: bold;
                    &:hover, &:focus{
                        text-decoration: none;
                        color: darken($red, 10%);
                    }
                    @media (max-width: 575px) {
                        display: block;   
                    }
                }
            } 
        }

    }

}