@font-face {
    font-family: 'Futura';
    src: url('../fonts/FuturaBT-Light.eot');
    src: url('../fonts/FuturaBT-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaBT-Light.woff2') format('woff2'),
        url('../fonts/FuturaBT-Light.woff') format('woff'),
        url('../fonts/FuturaBT-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Futura';
    src: url('../fonts/FuturaBT-Heavy.eot');
    src: url('../fonts/FuturaBT-Heavy.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaBT-Heavy.woff2') format('woff2'),
        url('../fonts/FuturaBT-Heavy.woff') format('woff'),
        url('../fonts/FuturaBT-Heavy.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Futura';
    src: url('../fonts/FuturaBT-Medium.eot');
    src: url('../fonts/FuturaBT-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaBT-Medium.woff2') format('woff2'),
        url('../fonts/FuturaBT-Medium.woff') format('woff'),
        url('../fonts/FuturaBT-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Futura';
    src: url('../fonts/FuturaBT-Book.eot');
    src: url('../fonts/FuturaBT-Book.eot?#iefix') format('embedded-opentype'),
        url('../fonts/FuturaBT-Book.woff2') format('woff2'),
        url('../fonts/FuturaBT-Book.woff') format('woff'),
        url('../fonts/FuturaBT-Book.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
