#product-product{
    
    .main-image{
        text-align: left;
        @media (max-width: 767px) {
            text-align: center;
            margin-bottom: 20px;
        }
        
    }
    
    .product-logo{
        border: 5px solid #ccc;
        margin-bottom: 20px;
    }
    
    .description{
        line-height: 1.7;
        h4{
            font-weight: 900;
            color: $red; 
            font-size: 18px;
            margin-top: 20px;
            line-height: 1.4;
        }
    }
    
    .red-button{
        letter-spacing: 2px;
        margin-top: 30px;
        margin-bottom: 0;
        cursor: pointer;
        @media (max-width: 767px) {
             letter-spacing: 1px;
            
        }
        @media (max-width: 575px) {
           letter-spacing: 0px;
        }
        
    }
    
    .website{
        margin-top: 10px;
        @media (max-width: 767px) {
           margin-bottom: 30px; 
        }
    }
    .retailer{
        line-height: 1.4;
        background-color: $red;
        width: 100%;
        font-size: 14px;
        padding: 30px ;
       
        background-image: url('/image/map-nm.png');
         
        background-position: center;
        background-size: 80% auto;
        background-repeat: no-repeat;
        color: #fff;
        @media (max-width: 991px) {
            background-position: 90% center; 
        }
        @media (max-width: 767px) {
            background-position: right center; 
        }
           

        h3{
            margin-top: 0;
            font-size: 45px;
            margin-bottom: 15px;
            @media (max-width: 991px) {
                font-size: 40px;
            }
        }

        .retailer-button{
            display: inline-block;
            padding-left: 38px;
            text-transform: uppercase;
            position: relative;
            line-height: 40px;
            color: #fff;
            font-size: 12px;
            letter-spacing: 1px;
            margin-top: 10px;
            @media (max-width: 767px) {
                letter-spacing: 0px;
            }
            @media (max-width: 575px) {
                font-size: 16px;
            }
            img{
                position: absolute;
                top: 0;
                left: 0;
            }

            &:hover, &:focus{
                text-decoration: none;
                color: #e96989;
            }

        }
        margin-bottom: 30px;
    }
    .news{
        background: $black;
        line-height: 1.4;
        font-size: 14px;
        padding: 30px ;
        h3{
            margin-top: 0;
            color: #fff;
            font-size: 45px;
            margin-bottom: 10px;
            @media (max-width: 991px) {
                font-size: 40px;
            }
        }
        
        
        .news-ticker{
            .item{
                color: #fff;
                h4{
                    color: #fff;
                    font-size: 15px;
                    margin-top: 0;
                    margin-bottom: 5px;
                }
                
                a{
                    font-size: 12px;
                    color: #fff;
                    &:hover, &:focus{
                        color: $red;
                        text-decoration: none;
                    }
                }
            }
            
            .owl-nav{
                text-align: left;
                .owl-next, .owl-prev{
                    border-radius: 0px;
                    width: 40px;
                    height: 35px;
                    margin: 0;
                    padding: 0;
                    text-align: center;
                    i{
                        line-height: 35px;
                        font-size: 34px;
                    }
                    
                }
                
                .owl-prev{
                    background: #ececec;
                    &:hover, &:focus{
                        background: darken(#ececec,10%);
                    }
                    color: #666;
                }
                
                .owl-next{
                     background: $red;
                     &:hover, &:focus{
                        background: darken($red,10%);
                    }
                }
            }
        }
    }
    .additional-images{
        position: relative;
        margin-bottom: 30px;
        .image{
            border: 5px solid #ccc;
            background-position: 50% 50%;
            background-size: cover;
            height: 347px;
            
        }
        .owl-nav{
                text-align: center;
                position: absolute;
                bottom: 15px;
                left: calc(50% - 40px);
                
                .owl-next, .owl-prev{
                    border-radius: 0px;
                    width: 40px;
                    height: 35px;
                    margin: 0;
                    padding: 0;
                    text-align: center;
                    i{
                        line-height: 35px;
                        font-size: 34px;
                    }
                    
                }
                
                .owl-prev{
                    background: #ececec;
                    &:hover, &:focus{
                        background: darken(#ececec,10%);
                    }
                    color: #666;
                }
                
                .owl-next{
                     background: $red;
                     &:hover, &:focus{
                        background: darken($red,10%);
                    }
                }
            }
        
    }
    
    .additional-images_old{
        
        img{
            border: 5px solid #ccc;
            margin-bottom: 30px;
        }
        
        
        .owl-nav{
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: $black;
                position: relative;
                &:before{
                    position: absolute;
                    content: "MORE IMAGES";
                    top: 0;
                    width: calc(100% - 80px);
                    left: 0;
                    text-align: center;
                    margin: 0 40px;
                    line-height: 35px;
                    color: #fff;
                    font-weight: 800;
                    font-family: Raleway;
                    letter-spacing: 2px;
                    
                }
                .owl-next, .owl-prev{
                    border-radius: 0px;
                    width: 40px;
                    height: 35px;
                    margin: 0;
                    padding: 0;
                    text-align: center;
                    i{
                        line-height: 35px;
                        font-size: 34px;
                    }
                    
                }
                
                .owl-prev{
                    background: #ececec;
                    &:hover, &:focus{
                        background: darken(#ececec,10%);
                    }
                    color: #666;
                }
                
                .owl-next{
                     background: $red;
                     &:hover, &:focus{
                        background: darken($red,10%);
                    }
                }
            }
        
    }
    h1 {
        margin-top: 0;
        margin-bottom: 40px;
        @media (max-width: 767px) {
             font-size: 30px;
              margin-bottom: 30px;
            
        }
        @media (max-width: 767px) {
             font-size: 25px;
              margin-bottom: 20px;
            
        }

    }
    #product-images {
        
        > a {
            display: block;
            text-align: left;
            margin-bottom: 17px;
        }
        .additional-images {
            text-align: left;
            .image {
                height: auto;
                display: inline-block;
                margin-bottom: 5px;
                max-width: 27.5%;
            }
            img {
            }
        }
    }   
    
    
    .product-downloads{
        .product-download{
            margin-top: 0;
            margin: 10px 0;
        }
    }
    
}